<template>
  <div id="legal-agreement">
    <b-container class="base-container-x" style="min-height:500px;background-color:#fff">
      <div v-if="loading == true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <div v-if="loading == false"  id="banner" class="banner-inner legal-agreement">
        <div class="legal_caption">
          <div class="legal_inner_caption">
            <div class="legal_align_caption">
              <h1 class="header-title text-uppercase font-weight-bold"><i>{{ $t('Legal Agreement') }}</i></h1>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loading == true" class="col-12 mt-4">
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
        <br/>
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-if="loading == false" class="col-12 mb-5 pt-4">
        <p>{{ $t('Before using our service, please make sure you have read and agree to these legal agreements. World Yoga Alliance® reserves the right to monitor your activities for compliance of our standard policies. By accessing these websites, we assume you accept these terms and conditions. Do not continue to use our Official Websites if you do not agree to take all of the terms and conditions stated on these agreements.') }}</p>
        <div class="section-title pl-5 pt-3 pb-3 mt-5">
          <router-link :to="{ name: 'termsAndConditions' }" class="d-flex align-items-center text-primary-dark link">
            <font-awesome-icon :icon="['fas', 'plus']" />
            <h4 class="d-none d-sm-block font-weight-bold ml-3">{{ $t('Terms & Conditions') }}</h4>
            <h5 class="d-block d-sm-none font-weight-bold ml-3">{{ $t('Terms & Conditions') }}</h5>
          </router-link>
        </div>
        <div class="section-title pl-5 pt-3 pb-3 mt-4">
          <router-link :to="{ name: 'designationAndTrademark' }" class="d-flex align-items-center text-primary-dark link">
            <font-awesome-icon :icon="['fas', 'plus']" />
            <h4 class="d-none d-sm-block font-weight-bold ml-3">{{ $t('Designation & Trademark') }}</h4>
            <h5 class="d-block d-sm-none font-weight-bold ml-3">{{ $t('Designation & Trademark') }}</h5>
          </router-link>
        </div>
        <div class="section-title pl-5 pt-3 pb-3 mt-4">
          <router-link :to="{ name: 'privacyPolicy' }" class="d-flex align-items-center text-primary-dark link">
            <font-awesome-icon :icon="['fas', 'plus']" />
            <h4 class="d-none d-sm-block font-weight-bold ml-3">{{ $t('Privacy Policy') }}</h4>
            <h5 class="d-block d-sm-none font-weight-bold ml-3">{{ $t('Privacy Policy') }}</h5>
          </router-link>
        </div>
        <div class="section-title pl-5 pt-3 pb-3 mt-4">
          <router-link :to="{ name: 'cancellationAndRefundPolicy' }" class="d-flex align-items-center text-primary-dark link">
            <font-awesome-icon :icon="['fas', 'plus']" />
            <h4 class="d-none d-sm-block font-weight-bold ml-3">{{ $t('Cancellation and Refund Policy') }}</h4>
            <h5 class="d-block d-sm-none font-weight-bold ml-3">{{ $t('Cancellation and Refund Policy') }}</h5>
          </router-link>
        </div>
        <div class="section-title pl-5 pt-3 pb-3 mt-4">
          <router-link :to="{ name: 'communityGuidelinesForMembers' }" class="d-flex align-items-center text-primary-dark link">
            <font-awesome-icon :icon="['fas', 'plus']" />
            <h4 class="d-none d-sm-block font-weight-bold ml-3">{{ $t('Community Guidelines for Members') }}</h4>
            <h5 class="d-block d-sm-none font-weight-bold ml-3">{{ $t('Community Guidelines for Members') }}</h5>
          </router-link>
        </div>
        <p class="mt-5">{{ $t('World Yoga Alliance® is a Non-Profit Yoga Organization. We are not affiliated or connected to any other Yoga Alliances / Organizations. We work independently for our yoga community.') }}</p>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'LegalAgreement',
  title: ' | Legal Agreement',
  data () {
    return {
      loading: true
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    await this.toggleNavbarNavClass('addClass', 'nav-clean')
    this.loading = false
  },
  computed: {
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    toggleNavbarNavClass (addRemoveClass, className) {
      const nav = document.querySelector('.navbar-nav')
      if (addRemoveClass === 'addClass') {
        nav.classList.add(className)
      } else {
        nav.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
    this.toggleNavbarNavClass('removeClass', 'nav-clean')
  }
}
</script>
<style scoped>
  #legal-agreement {
  /* Lock Text */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  .header-title {
    font-size: 70px;
  }
  @media screen and (max-width: 767.98px) {
    .header-title {
      font-size: 40px;
    }
  }
  .section-title {
    background-color: #e8ecf8;
  }
  .link:hover {
    color: red!important;
  }
</style>
